import { useEffect, useRef, useState } from 'react';
import Ticker from 'react-ticker'
import Marquee from "react-fast-marquee";

import sanityClient from "./client";
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import './App.scss';
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom';

import Accordion from "./components/accordion/accordion.component"
import NewsAccordion from "./components/newsAccordion/newsaccordion.component"
import Slideshow from "./components/slideshow/slideshow.component"

import {TfiClose} from 'react-icons/tfi'
import {BsInstagram} from 'react-icons/bs'
import {BsThreads} from 'react-icons/bs'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

function App() {

  const [squareSectionOpen, setSquareSectionOpen] = useState(false);
  const [pageContent,setPageContent] = useState(null)
  const [rssContent, setRssContent] = useState(null)

  const [focus, setFocus] = useState(null)
  const [topicOpen,setTopicOpen] = useState(null)
  const [newsOpen,setNewsOpen] = useState(null)

  const [aboutTextState, toggleAboutTextState] = useState(false)
  const [narrowNewsState,toggleNarrowNewsState] = useState(false)
  const [narrowTopicsShow, toggleNarrowTopicsShow] = useState(false)
  const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })
  const [columnHeight, setColumnHeight] = useState(window.innerHeight)

  const [checkHeight, setCheckHeight] = useState(true)

  // const [currentLocation, setCurrentLocation] = useState(null);

  const narrowTopicsWrapperRef = useRef();
  const colofonRef = useRef()

  const [rssUrl, setRssUrl] = useState("http://rss.cnn.com/rss/edition_world.rss");
  const [rssItem, setItems] = useState(null);

  let location = useLocation();
  const navigate = useNavigate();

  const handleSlugContent = (location)=>{
    if (location[location.length-2] == 'News'){
      let currentNewsContent = pageContent.news.find(item =>{
        return location[location.length-1] === item.Slug.current
      })
      setSquareSectionOpen(currentNewsContent)
      setFocus('news')
    }
    if (location[location.length-2] == 'Topic'){
      setTopicOpen(location[location.length-1])
      setFocus('topic')
    }
  }

  useEffect(() => {
    if (location.pathname && pageContent){
      let currentLoc = location.pathname.split('/')
      handleSlugContent(currentLoc)
    }
  },[location, pageContent]);

useEffect(()=>{
  if(rssItem) return
  const getRss = async (e) => {
    // e.preventDefault();
    const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
    if (!urlRegex.test(rssUrl)) {
      console.log('invalid')
      return;
    }
    const res = await fetch(`https://api.allorigins.win/get?url=${rssUrl}`);
    // const { contents } = await res.json();
    const { contents } = await res.json();
    const feed = new window.DOMParser().parseFromString(contents, "text/xml");
    const items = feed.querySelectorAll("item");
    // console.log(items)
    const item = {'feed': 'CNN', 'text': items[3].firstChild.textContent}
    // console.log(JSON.parse(contents))
    // const feedItems = [...items].map((el) => ({
    //   // link: el.querySelector("link").innerHTML,
    //   title: el.querySelector("title").innerHTML,
    //   // author: el.querySelector("author").innerHTML
    // }));
    setItems(item);
  };

  getRss();

    // var request = new XMLHttpRequest();
    // request.onreadystatechange = () => {
    //   if (request.readyState == 4 && request.status == 200) {
    //     var myObj = JSON.parse(request.responseText);
    //     console.log(myObj);
    //     for (var i = 0; i < 1; i ++) {
    //       setRssContent({name: myObj.items[i].title, url: myObj.items[i].link})
    //     }
    //   }
    // }
    // request.open("GET", "https://www.feedforall.com/sample.xml", true);
    // request.send();
  
})

// useEffect(()=>{
//   console.log(rssItem)
// },[rssItem])


useEffect(() =>{ 
    let fetchContentQuery = `{'about':*[_type == "about"]{...}[0], 'topics':*[_type == "topic"]{...}|order(orderRank), "news":*[_type == "news"]{...}|order(orderRank asc)}`
    sanityClient
    .fetch(fetchContentQuery)
    .then(data => {setPageContent(data)})
    .catch(console.error)

    const handler = () => {
      if (window.innerHeight != columnHeight){
        // console.log('innerHeight')
          setColumnHeight(window.innerHeight)
      }
    };
    window.addEventListener('resize', handler);
    return () => {
        window.removeEventListener("resize", handler);
    };
},[]);

useEffect(()=>{
  // console.log(topicOpen);
  if(topicOpen){
    setFocus('topic');
    toggleNarrowTopicsShow(true)
    setSquareSectionOpen(false)
  }
  if (topicOpen == null && focus == 'topic'){
    setFocus(null)
  }
},[topicOpen])

const setFocusHandler = (newFocus)=>{
  setTopicOpen(null)
  if(focus == newFocus){
    setFocus(null);
  } else{
    setFocus(newFocus);
  }
}

const logoClickHandler = ()=>{
  // console.log('clicked logo')
  setSquareSectionOpen(null)
  setFocusHandler(null)
  toggleNarrowTopicsShow(false)
  toggleNarrowNewsState(false)
  navigate(`/`)
}

const newsClickHandler = (item)=>{
  if (item){
    setFocusHandler('news')
    navigate(`/News/${item.Slug.current}`)
  }else{
    setSquareSectionOpen(null)
    setFocusHandler(null)
    navigate(`/`)
  }
}

const NarrowNewsSquare = ({index, item}) =>{

  const [isOpen, setIsopen] = useState()

  if (!isOpen){
    if (squareSectionOpen){
      if('current' in squareSectionOpen.Slug){
        if(item.Slug.current == squareSectionOpen.Slug.current){
          // console.log('open')
          // console.log(squareSectionOpen.Slug.current)
          setIsopen(true)
        }else{
          // console.log('close')
          // setIsopen(false)
        }
      }
    }
  }

  const narrowNewsClickHandler = (state)=>{
   if (state){
    navigate(`/News/${item.Slug.current}`)
   }else{
    setSquareSectionOpen(null)
    navigate(`/`)
    // setIsopen(false)
   }
  }

  return (
    <div className='narrow-news-square'>

    {isOpen ?

      <div className='square-section-open'>
        {/* {console.log(item)} */}
        <div className='square-section-open-type'>
          {item.type}
        </div>
        <div className='square-section-close-button'
        onClick={() =>  narrowNewsClickHandler(false)}
        >
          <TfiClose/>
        </div>
        <div className='square-section-open-content'>
          <div className='large-header narrow-large-header'>
              <PortableText
                value={item.title}
              />
          </div>
          <div className='subtitle narrow-subtitle'>
            {'subtitle' in item ?
              <PortableText
                value={item.subtitle}
              />
            : ''}
          </div>
          <div className='subtitle narrow-subtitle'>
            {'subtitleBottom' in item ?  
            <PortableText
            value={item.subtitleBottom}
          />
          : ''}
          </div>

          <div className='square-section-open-text'>
            <PortableText
                value={item.text}
            />
          </div>

          <div className='square-section-open-slideshow'>
            {'images' in item ?
              <Slideshow isNarrow={isNarrow} images={item.images} />
            :''
            }
          </div>
          <div className='square-section-open-credits'>
              <PortableText
                    value={squareSectionOpen.credits}
                />
              </div>
        </div>
      </div>
  :
  <>
          <div className='square'
          onClick={() => narrowNewsClickHandler(true)}
          >
            <div className='square-content-image-wrapper'>
            <div className='square-meta'>
              {item.type}
            </div>
            <div className='square-content-image'>
              <img src={urlFor(item.coverImage).width(Math.round(window.innerWidth)).url()}/>
            </div>
            </div>
          </div>
    </>
  }
    
    </div>
  )

}

const ReturnArrowTitle = ({children, type}) =>{
 if (type == 'center'){
  return(
    <span className='arrow-title'>
      ► {children} <span className='arrow-title-right'>►</span>
    </span>
  )
 }
 if (type == 'right'){
  return(
    <span className='arrow-title'>
      ► {children} ►
    </span>
  )
 }
}

// console.log(pageContent)
if(!pageContent) return <></>
  return (
    <div className="app">


{/* // ABOUT // */}
{isNarrow ?

  <div
      className={`third left`}
      >
        <div
        onClick={()=>logoClickHandler()}
        className='header black large-header fixed uppercase'>
          {pageContent.about.title}
        </div>
        <div className='left-intro-content'>
          <div className='left-intro-content-title mid-header'>
          Research platform for<br/>
journalism in art & design
          </div>
          <div className={`narrow-about-text ${aboutTextState ? 'narrow-about-text-show' : ''}`}>
          <PortableText
            value={pageContent.about.text}
          />
          </div>
            <div className='left-intro-content-read-more' onClick={()=>{toggleAboutTextState(!aboutTextState)}}>
            {aboutTextState ?
            'Close'
            :
            <ReturnArrowTitle type={'right'}>Read more</ReturnArrowTitle>
            }
            </div>
        </div>
        <div className='twitter-header black'>
          {rssItem ?
          <Marquee gradient={false}>
            {rssItem.feed}: {rssItem.text}
          </Marquee>
                  :
                  '' 
          }
        </div>
      </div>
    :
      <div
      
      style={{height:columnHeight}}
      // onClick={()=>setFocusHandler('about')}
      className={`third left ${focus == null ? '' : focus == 'about' ? 'focus' : 'nofocus'}`}>
        <div
        onClick={()=>logoClickHandler()}
        className='pointer-cursor header black large-header'>
          {pageContent.about.title}
        </div>
        <div
        style={{height: focus == 'about' ? window.innerHeight / 1.8 : window.innerHeight / 3}}
        className={`left-intro-content ${focus == 'about' ? 'left-intro-content-expanded' : '' }`}>
          <div className='left-intro-content-title mid-header'>
          Research platform for<br/>
          journalism in art & design
          </div>
          <div
            onClick={()=>setFocusHandler(null)}
            className={`close-button ${focus == null ? '' : focus == 'about' ? 'close-button-show' : ''}`}>
            <TfiClose/>
          </div>

          <div
          className='left-intro-content-text'>
            {focus == 'about' ?
                      <PortableText
                      value={pageContent.about.text}
                    />
            :
                      <PortableText
                      value={pageContent.about.excerpt}
                    /> 
          }
          </div>
            <div
            onClick={()=>{setFocusHandler('about')}}
            style={{padding: focus == 'about' ? '40px 0px' : '0px'}}
            className='left-intro-content-read-more'>
            {focus == 'about' ?
            'Close'
            :
            <ReturnArrowTitle type={'right'}>Read more</ReturnArrowTitle>
            }
            </div>
        </div>
        <div className='twitter-header black'>

            {rssItem ?
              <Marquee pauseOnHover={true} speed={50} gradient={false}>
                <div className='ticker-content'>{rssItem.feed}: {rssItem.text} </div>
              </Marquee>
                :
                '' 
            }

        </div>
        <div
          onClick={()=>{setFocusHandler(null)}}
          // style={{height: focus == 'about' ? window.innerHeight / 9 : window.innerHeight / 3}}
          className={`left-intro-contact ${focus == 'about' ? 'left-intro-contact-contracted' : ''}`}
        >

          <div
          className='left-intro-contact-title mid-header'>
            Colofon
          </div>
          <div
          // ref={colofonRef}
          style={{height: focus == 'about' ? '0px' : 'auto'}}
          className='left-intro-contact-text'>
    
          <PortableText
            value={pageContent.about.credits}
          />
            
          </div>

        </div>
        <div className='header black socials'>
          <a href={pageContent.about.instagramUrl}>
            <BsInstagram/>
          </a>
          <a href={pageContent.about.threadsUrl}>
            <BsThreads/>
          </a>
        </div>
      </div>
}

{/* // TOPICS // */}

{isNarrow ?
  <div className={`third middle`}>
    <div
    onClick={()=>{toggleNarrowTopicsShow(!narrowTopicsShow)}}
    className='narrow-topics-button header'>
      TOPICS
    </div>
    <div
    ref={narrowTopicsWrapperRef}
    className={`narrow-topics-wrapper ${narrowTopicsShow ? 'narrow-topics-show' : ''}`}>
        {pageContent.topics.map((topic, index)=>{
          return (
            <Accordion focus={focus} setFocus={setFocus} topicOpen={topicOpen} setTopicOpen={setTopicOpen} slug={topic.slug} title={topic.title} checkHeight={checkHeight} isNarrow={isNarrow}>
            <div className='accordion-section'>
            <div className='accordion-link-section'>
              <ReturnArrowTitle type={'center'}>journalism</ReturnArrowTitle>
              {'links' in topic ?
                topic.links.map((link, index)=>{
                  // console.log(link)
                  return(
                    <div className='accordion-link'>
                      <PortableText
                        value={link.link}
                      />
                    </div>
                  )
                  })
                :''}
              </div>
              <div className='accordion-topic-text'>
                {'text' in topic ?
                  <PortableText
                    value={topic.text}
                  />
                :''}
              </div>
              <div className='accordion-topic-designers-wrapper'>
                <div className='arrow-title-wrapper'>
                  <ReturnArrowTitle type={'center'}>
                    Art and design
                  </ReturnArrowTitle>
                </div>
                
                {'designers' in topic ?
                topic.designers.map((designer, index) =>{
                  return(
                    <div className='accordion-topic-designer'>
                      <div className='accordion-topic-designer-slideshow'>
                        {'images' in designer ?
                        <Slideshow setCheckHeight={setCheckHeight} isNarrow={isNarrow} images={designer.images}/>
                        :''}
                        
                      </div>
                      <div className='accordion-topic-designer-text'>
                        <PortableText
                          value={designer.text}
                        />
                      </div>
                    </div>
                  )
                }) 
                :''
                }
              </div>
            </div>
          </Accordion>
          )
        })}    
        </div>   
      </div>
:
      <div
      style={{height:columnHeight}}
      className={`third middle ${focus == null ? '' : focus == 'topic' ? 'focus' : 'nofocus'}`}>
        {pageContent.topics.map((topic, index)=>{
          // console.log(topic)
          return (
            <Accordion focus={focus} setFocus={setFocus} topicOpen={topicOpen} setTopicOpen={setTopicOpen} slug={topic.slug} title={topic.title} checkHeight={checkHeight}>
            <div className='accordion-section'>
              <div className='accordion-link-section'>
              <ReturnArrowTitle type={'center'}>journalism</ReturnArrowTitle>
              {'links' in topic ?
                topic.links.map((link, index)=>{
                  // console.log(link)
                  return(
                    <div className='accordion-link'>
                      <PortableText
                        value={link.link}
                      />
                    </div>
                  )
                  })
                :''}
              </div>
              <div className='accordion-topic-text'>
                {'text' in topic ?
                  <PortableText
                    value={topic.text}
                  />
                :''}
              </div>
              <div className='accordion-topic-designers-wrapper'>
              <div className='center-title'>
                <ReturnArrowTitle type={'center'}>Art and design</ReturnArrowTitle>
              </div>
                {/* <div className='center-title'>Art and design</div> */}
                {'designers' in topic ?
                topic.designers.map((designer, index) =>{
                  return(
                    <div key={'slideshow' + index} className='accordion-topic-designer'>
                      <div className='accordion-topic-designer-slideshow'>
                        {'images' in designer ?
                        <Slideshow setCheckHeight={setCheckHeight} isNarrow={isNarrow} images={designer.images}/>
                        :''}
                        
                      </div>
                      <div className='accordion-topic-designer-text'>
                        <PortableText
                          value={designer.text}
                        />
                      </div>
                    </div>
                  )
                }) 
                :''
                }
              </div>
            </div>
          </Accordion>
          )
        })}       
      </div>
}

{/* // NEWS // */}

{isNarrow ?

  <div className={`third right`}>
    <div 
    onClick={()=>toggleNarrowNewsState(!narrowNewsState)}
    className='narrow-news-button header'>
      NEWS
    </div>

        {
        narrowNewsState ?
          pageContent.news.map((item,index) =>{
            return(
              <NarrowNewsSquare index={index} item={item} />
            )
          })
        :''
        }  
       
      </div>
    :
      <div
      style={{height:columnHeight}}
      className={`third right ${focus == null ? '' : focus == 'news' ? 'focus' : 'nofocus'}`}>

        {squareSectionOpen && focus == 'news' ?
        
        <div className='square-section-open'>
          {/* {console.log(squareSectionOpen)} */}
            <div className='square-section-open-type'>
              {squareSectionOpen.type}
            </div>
            <div
            className='square-section-close-button'
            onClick={() => {newsClickHandler(false)}}>
              <TfiClose/>
            </div>
            <div className='square-section-open-content'>
              <div className='square-section-open-title-wrapper'>
                <div className='large-header'>
                  <PortableText
                    value={squareSectionOpen.title}
                  />
                </div>
                <div className='subtitle'>
                  {'subtitle' in squareSectionOpen ?
                    <PortableText
                      value={squareSectionOpen.subtitle}
                    />
                  : ''}
                </div>
                <div className='subtitle'>
                  {'bottomSubtitle' in squareSectionOpen ?
                    <PortableText
                      value={squareSectionOpen.bottomSubtitle}
                    />
                  : ''}
                </div>
              </div>
              <div className='square-section-open-text'>
                <PortableText
                    value={squareSectionOpen.text}
                />
              </div>

              <div className='square-section-open-slideshow'>
                {'images' in squareSectionOpen ?
                <Slideshow isNarrow={isNarrow} images={squareSectionOpen.images} />
                :''
                }
              </div>
              <div className='square-section-open-credits'>
              <PortableText
                    value={squareSectionOpen.credits}
                />
              </div>
            </div>
        </div>
        :''
        }

        {pageContent.news.map((item,index) =>{
          return(
            <>
            {index % 2 == 0 ?
            <div className='square-pair'>
            
            <div className='square' onClick={() => {newsClickHandler(item)}}>
              <div className='square-content-image-wrapper'>
              <div className='square-meta'>
                {item.type}
              </div>
              <div className='square-content-image'>
                <img src={urlFor(item.coverImage).width(Math.round(window.innerWidth)).url()}/>
              </div>
              </div>
            </div>

            <div className='square' onClick={() => {newsClickHandler(item)}}>
              <div className='square-content'>
                <div className='square-content-title-wrapper'>
                  <div className='square-content-title large-header'>
                  <PortableText
                      value={item.title}
                    />
                  </div>
                  <div className='square-content-subtitle'>
                  {'subtitle' in item ?
                    <PortableText
                      value={item.subtitle}
                    />
                  : ''}
                  </div>
                  </div>
                  <div className='square-content-subtitle-bottom'>
                  {'bottomSubtitle' in item ?
                    <PortableText
                      value={item.bottomSubtitle}
                    />
                  : ''}
                  </div>
               
              </div>
            </div>
            </div>
          :
          <div className='square-pair'>
          <div className='square' onClick={() => {newsClickHandler(item)}}>
            <div className='square-content'>
              <div className='square-content-title-wrapper'>
              <div className='square-content-title large-header'>
                  <PortableText
                      value={item.title}
                    />
                  </div>
                  <div className='square-content-subtitle'>
                  {'subtitle' in item ?
                    <PortableText
                      value={item.subtitle}
                    />
                  : ''}
                  </div>
                  </div>
                  <div className='square-content-subtitle-bottom'>
                  {'bottomSubtitle' in item ?
                    <PortableText
                      value={item.bottomSubtitle}
                    />
                  : ''}
                  </div>

            </div>
          </div>
            <div className='square' onClick={() => {newsClickHandler(item)}}>
              <div className='square-content-image-wrapper'>
              <div className='square-meta'>
                {item.type}
              </div>
              <div className='square-content-image'>
                <img src={urlFor(item.coverImage).width(Math.round(window.innerWidth)).url()}/>
              </div>
              </div>
            </div>

            </div>
            }
          </>
          )
        })}  
      </div>
}

{isNarrow ?
        <>
        <div
          className={`left-intro-contact`}
        >
        <div
        className='left-intro-contact-title mid-header'>
          Colofon
        </div>
        <div
        className='left-intro-contact-text'>
  
        <PortableText
          value={pageContent.about.credits}
        />
        </div>

      </div>
        <div style={{float:'left',width:'100%'}} className='header black socials'>
          <a href={pageContent.about.instagramUrl}>
            <BsInstagram/>
          </a>
          <a href={pageContent.about.threadsUrl}>
            <BsThreads/>
          </a>
        </div>
      </>
:
''
}
    </div>
  );
}

export default App;
