import React, {useEffect, useState, useRef} from "react";

import './slideshow.styles.scss';

import sanityClient from "../../client";
import imageUrlBuilder from '@sanity/image-url'
import {getImageDimensions} from '@sanity/asset-utils'


import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {BiFullscreen} from 'react-icons/bi'
import {BiExitFullscreen} from 'react-icons/bi'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

const Slideshow = ({setCheckHeight, isNarrow, images}) =>{
    // console.log(isNarrow)
   
    const [counter, setCounter] = useState(null)
    const [height, setHeight] = useState(300)
    const [expandedWidth, setExpandedWidth] = useState(false)
    const slideshow = useRef()
    const [slideshowReady, setSlideshowReady] = useState(false)
    const navigationPrevRef = useRef()
    const navigationNextRef = useRef()

    useEffect(()=>{
        if(slideshowReady)return
        if('current' in slideshow){
            setSlideshowReady(true)
        }
    },[slideshow])

    // const heightToggleHandler = () =>{ // how to not squash images that are already full width
    //     if(height == 300){
    //         setHeight(500)
    //     }else{
    //         setHeight(300)
    //     }
    // }

    const widthToggleHandler = ()=>{
        window.setTimeout(function(){
            setCheckHeight(Date.now())
        },0)
        // console.log(slideshow.current.clientWidth)
        if(expandedWidth){
            setExpandedWidth(false)
        }else{
            setExpandedWidth(slideshow.current.clientWidth)
        }
    }

    const returnWidth = () =>{
        // console.log('narrow')
        if(height == 300){
            return window.innerWidth * 0.6
        }else{
            return window.innerWidth - 30
        }
    }

    // const returnImageDimensions = (asset)=>{
    //     const { aspectRatio} = getImageDimensions(asset)
    //     if(expandedWidth){
    //         return {width: slideshow.current.clientWidth * 1.2, height: (slideshow.current.clientWidth * 1.2) / aspectRatio}
    //     }else{
    //         return {width: '100%', height: }
    //         // return {width: slideshow.current.clientWidth, height: slideshow.current.clientWidth / aspectRatio}
            
    //     }
    // }

    const returnImageDimensions = (asset)=>{
        
        const { aspectRatio} = getImageDimensions(asset)
        if(expandedWidth){
            return {width: slideshow.current.clientWidth, height: slideshow.current.clientWidth / aspectRatio}
        }else{
            return {width: slideshow.current.clientWidth * 0.7, height: (slideshow.current.clientWidth * 0.7) / aspectRatio}
        }
    }

    return (
        <div
        ref={slideshow}
        className='slideshow-wrapper'>
            <div className="slideshow-controls">
                <div className="slideshow-counter">
                    {counter} / {images.length}
                </div>
                <div
                onClick={()=>widthToggleHandler()}
                className="slideshow-image-scale">
                    {!expandedWidth ?
                    <BiFullscreen/>
                    :
                    <BiExitFullscreen/>
                    }
                </div>
            </div>
            <Swiper
                modules={[Navigation]}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}     
                onSlideChange={(el) => setCounter(el.realIndex + 1)}     
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                className="mySwiper"
                // autoHeight={true}
            >   
                <div
                    className="swiper-ref-prev"
                    ref={navigationPrevRef}
                >
                <span className='arrow-title-right'>►</span>
                </div>
                <div
                    className="swiper-ref-next"
                    ref={navigationNextRef}   
                >
                ►
                </div>
                {images.map((slide, index) =>{
                    return (

                        <SwiperSlide key={slide.asset._ref}>
                            <div className="slideshow-wrapper-image">
                                {isNarrow && slideshowReady ?
                                    <img
                                    style={returnImageDimensions(slide.asset)} 
                                    src={urlFor(slide.asset._ref).width(Math.round(window.innerWidth)).url()}/>  
                                :
                                !isNarrow && slideshowReady ?
                                    <img
                                    style={returnImageDimensions(slide.asset)} 
                                    src={urlFor(slide.asset._ref).width(Math.round(window.innerWidth / 2)).url()}/>  
                                :
                                ''
                                }
                                {'caption' in slide ?
                                    <div className="slideshow-wrapper-image-caption">
                                        {slide.caption}
                                    </div>
                                :''
                                }
                            </div>
                        </SwiperSlide>
                    
                    )
                })}
                
            </Swiper>
        </div>
    )
}

export default Slideshow