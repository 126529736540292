import React, {useEffect, useState, useRef,useCallback} from "react";
import {TfiClose} from 'react-icons/tfi'
import { useNavigate } from "react-router-dom";

import './accordion.styles.scss';

const Accordion = ({focus, setFocus, topicOpen, setTopicOpen, title, slug, children, checkHeight, isNarrow}) =>{
    const [isOpen, setIsOpen] = useState(false);
    const [showContent, setShowContent] = useState(false)
    const [currentHeight, setCurrentHeight] = useState(null)
    const contentRef = useRef();
    const accordionRef = useRef();
    const navigate = useNavigate();

    // const [, updateState] = useState();
    // const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(()=>{
        if (slug != undefined){
            if (topicOpen == slug.current){
                setIsOpen(true)
                window.setTimeout(function(){
                    console.log('showing', slug.current)
                    setShowContent(true)
                },focus == 'topic' ? 0 : 300)
            }
            if(topicOpen == null){
                setIsOpen(false)
                setShowContent(false)
            }
        }
    },[topicOpen])

    // somehow check if there are still other accordions open, make a function to close all?
    // automatically set focus to topics if any accordion is open

    const accordionClickHandler = ()=>{
        // console.log(contentRef.current.getBoundingClientRect())
        // console.log(slug.current)

        if (topicOpen == slug.current){
            setTopicOpen(null)
            navigate("/");
            // set slug to null
        }else{  
            console.log('opening')
            navigate(`/Topic/${slug.current}`);
            setTopicOpen(slug.current)
        }

    }

    useEffect(()=>{
        
            window.setTimeout(function(){
                setCurrentHeight(contentRef.current.scrollHeight);
                // forceUpdate()
            },300)
        
    },[checkHeight])

    useEffect(()=>{
        if ('scrollHeight' in contentRef.current){
                setCurrentHeight(contentRef.current.scrollHeight)
        }

    },[contentRef.current])

        // console.log(children)

    
    return (
        <div className='accordion'>
            <div className='header' onClick={() => accordionClickHandler()}>
                {title}

                {isOpen ? 
                    <div
                        className={`accordion-close-button`}>
                        <TfiClose/>
                    </div>
                :
                ''
                }
            </div>
            <div
             ref={contentRef}
             style={
                showContent 
                // && checkHeight && currentHeight
                  ? { 
                      height: isNarrow ? contentRef.current.scrollHeight : currentHeight, //somehow the first opened has more space in the bottom?
                      opacity: 1
                     }
                  : { 
                      marginTop: "0px",
                      height: "0px",
                      opacity: 0
                    }
              }
             className={`content-wrapper ${showContent ? 'open' : ''}`}>
                {children}
                {/* <Children/> */}
            </div>
        </div>
    )
}

export default Accordion